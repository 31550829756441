import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Khumalo Quantity Surveyors And Associates </p>
      <p>Mzinto AA 1Swallow Drive Sandton, Mount Frere, 5090</p>
    </div>
  );
};

export default Footer;